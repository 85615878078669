import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { InstantViewModule } from './instant-view/instant-view.module';
import { HomecheckViewModule } from './homecheck-view/homecheck-view.module';
import { TimelineModule } from './timeline/timeline.module';
import { ExitModule } from './exit/exit.module';
import { BoL1QueueModule } from './bo-l1-queue/bo-l1-queue.module';

import { ObeL1UiModule } from '@libraries/obe-l1-ui/obe-l1-ui';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    InstantViewModule,
    HomecheckViewModule,
    TimelineModule,
    ExitModule,
    BoL1QueueModule,
    ObeL1UiModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
