import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SpaL1Service } from '../services/spa-l1.service';

@Component({
  selector: 'obe-bo-l1-queue',
  templateUrl: './bo-l1-queue.component.html',
  styleUrls: ['./bo-l1-queue.component.scss']
})
export class BoL1QueueComponent implements OnChanges {

  constructor(private router:Router, private service: SpaL1Service) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  refreshJwt() {
    this.service.setJwtToken().subscribe();
  }

}
