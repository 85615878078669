import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SpaL1Service } from '../services/spa-l1.service';

@Component({
  selector: 'obe-exit',
  templateUrl: './exit.component.html',
  styleUrls: ['./exit.component.scss']
})
export class ExitComponent implements OnChanges {

  constructor(private router:Router, private service: SpaL1Service) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  redirectTo(event: string) {
    switch (event) {
      case 'bo-l1-queue':
        this.router.navigate(['bo-l1-queue']);
        break;
      default:
        break;
    }
  }

  refreshJwt() {
    this.service.setJwtToken().subscribe();
  }

}
