import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstantViewComponent } from './instant-view.component';

const routes: Routes = [
  {
    path: '', 
    component: InstantViewComponent,
    canActivate:[]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InstantViewRoutingModule { }
