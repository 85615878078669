import { Injectable } from "@angular/core";
import { map, tap } from 'rxjs/operators';
import { ApiSpaL1Service } from "./api/api-spa-l1.service";

@Injectable({
    providedIn: 'root'
})
export class SpaL1Service {
    constructor(private apiService: ApiSpaL1Service) { }

    setJwtToken() {
        // return this.apiService.setLoginAuthority().pipe(
        //     map((data: any) => sessionStorage.setItem('jwt', data.result.newAuthToken))
        // )
        return this.apiService.setLoginAuthority();
    }
}