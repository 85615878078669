import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoL1QueueRoutingModule } from './bo-l1-queue-routing.module';
import { BoL1QueueComponent } from './bo-l1-queue.component';
import { ObeBoL1QueueModule } from '@libraries/obe-bo-l1-queue';

import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [BoL1QueueComponent],
  exports: [BoL1QueueComponent],
  imports: [
    CommonModule,
    BoL1QueueRoutingModule,
    // RouterModule,
    ObeBoL1QueueModule,
    HttpClientModule

  ]
})
export class BoL1QueueModule { }
