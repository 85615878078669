import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig, APP_CONFIG } from './app/shared/services/app.config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch('./assets/env-config/environment.json')
  .then((response) => response.json())
  .then((env) => {
    fetch('./assets/env-config/'+ env['env'] +'/spa-' + env['env'] + '-config.json')
    .then((response) => response.json())
    .then((config) => {
    AppConfig.config = config;
    if (environment.production) {
      enableProdMode()
    }
     platformBrowserDynamic()
       .bootstrapModule(AppModule)
       .catch((err) => console.error(err));
    });
  });
