import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomecheckViewRoutingModule } from './homecheck-view-routing.module';
import { HomecheckViewComponent } from './homecheck-view.component';
import { ObeOverviewModule } from '@libraries/obe-overview';
import { ObeInstantViewModule } from '@libraries/obe-instant-view';
import { ObeHomecheckViewModule } from '@libraries/obe-homecheck-view';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HomecheckViewComponent],
  exports: [HomecheckViewComponent],
  imports: [
    CommonModule,
    HomecheckViewRoutingModule,
   // RouterModule,
    ObeOverviewModule,
    ObeInstantViewModule,
    ObeHomecheckViewModule

  ]
})
export class HomecheckViewModule { }
