import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InstantViewRoutingModule } from './instant-view-routing.module';
import { InstantViewComponent } from './instant-view.component';
import { ObeOverviewModule } from '@libraries/obe-overview';
import { ObeInstantViewModule } from '@libraries/obe-instant-view';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [InstantViewComponent],
  exports: [InstantViewComponent],
  imports: [
    CommonModule,
    InstantViewRoutingModule,
   // RouterModule,
    ObeOverviewModule,
    ObeInstantViewModule

  ]
})
export class InstantViewModule { }
