import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SpaL1Service } from '../services/spa-l1.service';

@Component({
  selector: 'obe-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnChanges {

  constructor(private router:Router, private service: SpaL1Service) { }

  ngOnInit(): void {

  }

  redirectToInstantView(tab: any) {
    this.router.navigate(['instant-view'], { queryParams: { tab } });
    //this.router.navigateByUrl('instant-view');
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  refreshJwt() {
    this.service.setJwtToken().subscribe();
  }
}
