import { throwError, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable, InjectionToken } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
interface ConfigInterface {
    [key: string]: any;
  }
@Injectable({
  providedIn: 'root'
})
export class AppConfig {
    static config: ConfigInterface = {};
    env: ConfigInterface = {};

  constructor(private http: HttpClient) {}

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: string) {
    return AppConfig.config[key];
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: string) {
    return this.env[key];
  }

  /**
   * Load Environment files
   *
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "env.[env].json" to get all env's variables (e.g.: 'env.development.json')
   */
  public load() {
    return new Promise((resolve, reject) => {
      this.http.get<any>('./assets/env-config/environment.json')
      .pipe(
        switchMap(envData=>{
          return this.http.get('./assets/env-config/' + envData.env + '/spa-' + envData.env + '-config.json').pipe(
            map(el=>{return el;}),
            map(configData=> {console.log('staticLoaddd');AppConfig.config = configData;}),
            catchError(this.handleError)
          )
        }),
        catchError(this.handleError)
      ).subscribe(()=>resolve(true));
    });
  }

  /**********************/
  /******* ERROR ********/
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}

export let APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG')
