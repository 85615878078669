import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InstantViewComponent } from './instant-view/instant-view.component';
import { HomecheckViewComponent } from './homecheck-view/homecheck-view.component';
import { TimelineComponent } from './timeline/timeline.component';
import { ExitComponent } from './exit/exit.component';
import { BoL1QueueComponent } from './bo-l1-queue/bo-l1-queue.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full'
  },
  {
    path: 'overview',
    component: DashboardComponent
  },
  {
    path: 'instant-view',
    component: InstantViewComponent
  },
  {
    path: 'homecheck-view',
    component: HomecheckViewComponent
  },
  {
    path: 'timeline',
    component: TimelineComponent
  },
  {
    path: 'exit',
    component: ExitComponent
  },
  {
    path: 'bo-l1-queue',
    component: BoL1QueueComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
