import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BoL1QueueComponent } from './bo-l1-queue.component';

const routes: Routes = [
  {
    path: '', 
    component: BoL1QueueComponent,
    canActivate:[]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BoL1QueueRoutingModule { }
