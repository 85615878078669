import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SpaL1Service } from '../services/spa-l1.service';

@Component({
  selector: 'obe-homecheck-view',
  templateUrl: './homecheck-view.component.html',
  styleUrls: ['./homecheck-view.component.scss']
})
export class HomecheckViewComponent implements OnChanges {

  constructor(private router:Router, private service: SpaL1Service) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  redirectTo(event: string) {
    switch (event) {
      case 'exit':
        this.router.navigate(['exit']);
        break;
      default:
        break;
    }
  }

  refreshJwt() {
    this.service.setJwtToken().subscribe();
  }
}
