import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimelineRoutingModule } from './timeline-routing.module';
import { TimelineComponent } from './timeline.component';
import { ObeTimelineModule } from '@libraries/obe-timeline';

import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [TimelineComponent],
  exports: [TimelineComponent],
  imports: [
    CommonModule,
    TimelineRoutingModule,
    // RouterModule,
    ObeTimelineModule,
    HttpClientModule

  ]
})
export class TimelineModule { }
