import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExitRoutingModule } from './exit-routing.module';
import { ExitComponent } from './exit.component';
import { ObeExitModule } from '@libraries/obe-exit';

import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [ExitComponent],
  exports: [ExitComponent],
  imports: [
    CommonModule,
    ExitRoutingModule,
    // RouterModule,
    ObeExitModule,
    HttpClientModule

  ]
})
export class ExitModule { }
