import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "src/app/shared/services/app.config";
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiSpaL1Service {
    constructor(protected http: HttpClient) { }

    setLoginAuthority() {
        let tokenURL = this.setToken();
        let testToken = this.setTestToken();
        if (AppConfig.config.bffConfig.env === 'prod') {
            if (tokenURL) {
                sessionStorage.setItem('jwt', tokenURL);
                return of(tokenURL);
            } else {
                return of('test');
            }
            
        } else {
            if (testToken) {
                return this.http.get('https://obe-nonprod-dev.apigee.net/api/obe/jwt-generator/v1?subject=B2CCZ&language=en&msisdn=32499496777').pipe(
                    map((data: any) => {
                        sessionStorage.setItem('jwt', data.token);
                        return data.token;
                    })
                );
            } else if (tokenURL) {
                sessionStorage.setItem('jwt', tokenURL);
                return of(tokenURL);
            } else {
                return of('test');
            }
        }
    }

    setToken() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('token');
    }

    setTestToken() {
        const tokParams = sessionStorage.getItem('testToken');
        return tokParams;
    }

}